import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, Case, PdfLink } from '@app/states/case/case.model';
import { Organization } from '@app/states/organization/organization.model';
import { Experiment, ExperimentId } from '@app/states/case/switcher/switcher-case.model';
import { MailData } from '@app/doi/mail-data.model';
import { Verification } from '@app/verification/verification.model';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import { APIEndpoints } from './api-endpoints';
import { NGXLogger } from 'ngx-logger';

export const BYPASS_ERROR_FLAG = new HttpContextToken(() => false);
export const BYPASS_AUTH_FLAG = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {}

  public checkVerification(verification: Verification): Observable<Case> {
    const verificationRequest = {
      verificationCode: verification.verificationCode,
      birthDate: verification.birthDate
        ? dayjs(verification.birthDate).format(environment.verificationDateFormat)
        : null,
    };
    return this.http
      .post(`${environment.apiURL}/${APIEndpoints.Auth}`, verificationRequest, {
        responseType: 'text',
        context: new HttpContext().set(BYPASS_ERROR_FLAG, true),
      })
      .pipe(map((response) => this.parseCase(response)));
  }

  public sendCase(caseObject: Case): Observable<Record<string, any>> {
    caseObject = { ...caseObject };
    delete caseObject.frontendOnlyFields;

    return this.http.post<Record<string, any>>(`${environment.apiURL}/${APIEndpoints.Case}`, caseObject);
  }

  public verifyMail(longCode: string, reminder = false): Observable<MailData> {
    return this.http
      .post<MailData>(`${environment.apiURL}/${APIEndpoints.Mail}`, {
        longMappingId: longCode,
        reminder: reminder,
      })
      .pipe(
        map((mailData) => {
          if (mailData.customerSalutation === null) {
            this.logger.warn('Customer salutation is null!');
            mailData.customerSalutation = '';
          }
          if (mailData.customerTitle === null) {
            this.logger.warn('Customer title is null!');
            mailData.customerTitle = '';
          }
          if (mailData.customerFirstName === null) {
            this.logger.warn('Customer firstname is null!');
            mailData.customerFirstName = '';
          }
          if (mailData.customerLastName === null) {
            this.logger.warn('Customer lastname is null!');
            mailData.customerLastName = '';
          }

          return mailData;
        }),
      );
  }

  public getOrganizationById(configId: string | null): Observable<Organization> {
    return this.http.post<Organization>(
      `${environment.apiURL}/${APIEndpoints.Organization}`,
      {
        configId: configId,
      },
      {
        context: new HttpContext().set(BYPASS_ERROR_FLAG, true),
      },
    );
  }

  public getExperimentById(id: ExperimentId): Observable<Experiment | undefined> {
    return this.http.post<Experiment>(
      `${environment.apiURL}/${APIEndpoints.Experiment}`,
      {
        experimentId: id,
      },
      {
        context: new HttpContext().set(BYPASS_ERROR_FLAG, true),
      },
    );
  }

  public searchAddress(text: string, country: string): Observable<Address[]> {
    return this.http.post<Address[]>(
      `${environment.apiURL}/${APIEndpoints.Location}`,
      {
        country: country,
        singleLine: text,
      },
      {
        context: new HttpContext().set(BYPASS_ERROR_FLAG, true),
      },
    );
  }

  private parseCase(rawData: any): Case {
    return JSON.parse(rawData, (key: string, value: any) => {
      switch (key) {
        default:
          return value ?? '';
      }
    }) as Case;
  }
}
